import React, { useState } from "react";

import { useStaticQuery, graphql, Link } from "gatsby";
import SEO from "../../../components/seo/seo";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import CardGeneric from "../../../components/card/card-generic";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import Button from "../../../components/custom-widgets/button";
import ModalGeneric from "../../../components/custom-widgets/modal-generic";
import LoginHelpModalData from "../../../components/login/login-help-modal-data";
import Icon from "../../../components/custom-widgets/icon";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import FaqAccordion from "../../../components/faq/faq-accordion";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";

// Helpers
import ZipCodeFilter from "../../../components/zip-code-filter/zip-code-filter";
import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";

const CreditCards = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/credit-cards/hero-personal-credit-card-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/personal-banking/credit-cards/hero-personal-credit-card-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/personal-banking/credit-cards/hero-personal-credit-card-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/personal-banking/credit-cards/hero-personal-credit-card-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/personal-banking/credit-cards/hero-personal-credit-card-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/personal-banking/credit-cards/hero-personal-credit-card-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/credit-cards/hero-personal-credit-card-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
      CardImage1: file(relativePath: { eq: "thumbnail-digital-wallet-06092023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage2: file(
        relativePath: { eq: "cards/articles-cards/thumbnail-using-credit-cards-wisely-03092023-250.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage3: file(relativePath: { eq: "cards/articles-cards/thumbnail-credit-card-payments-03082023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const SEOData = {
    title: "Cash Back Credit Card",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Cash Back Credit Card"
      },
      {
        name: "description",
        content: "Learn about the WaFd Bank cash back rewards credit card. Apply Today!"
      },
      {
        property: "og:title",
        content: "Cash Back Credit Card"
      },
      {
        property: "og:description",
        content: "Learn about the WaFd Bank cash back rewards credit card. Apply Today!"
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/credit-cards"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-personal-credit-card.jpg"
      }
    ]
  };

  const [showHelp, setShowHelp] = useState(false);
  const handleCloseHelp = () => setShowHelp(false);
  const handleShowHelp = (e) => {
    e.preventDefault();
    setShowHelp(true);
  };

  const [showCashBackCardRate, setShowCashBackCardRate] = useState(false);
  const toggleShowCashBackCardRate = () => {
    setShowCashBackCardRate(!showCashBackCardRate);
  };

  const [showSecureCreditCardData, setShowSecureCreditCardData] = useState(false);
  const toggleShowSecureCreditCardData = () => {
    setShowSecureCreditCardData(!showSecureCreditCardData);
  };

  const loginHelpModalData = {
    handleClose: handleCloseHelp,
    show: showHelp,
    modalSize: "md",
    header: {
      id: "cc-sign-in-help-modal-title",
      class: "mb-0",
      text: "Sign In Help"
    },
    body: {
      class: "",
      content: [<LoginHelpModalData />]
    }
  };

  const [showZipCodeModal, setShowZipCodeModal] = useState(false);
  const handleCloseZipCodeModal = () => setShowZipCodeModal(false);
  const handleShowZipCodeModal = () => {
    setShowZipCodeModal(true);
  };

  const wafdCardsBtnData = {
    id: "credit-card-sign-in-btn",
    externalLink: true,
    class: "btn-success btn-md btn-block no-max-width text-center",
    text: "Sign In",
    showIcon: false,
    url: "https://www.ezcardinfo.com/?WAFD=/",
    target: "blank",
    style: {
      minWidth: "0"
    }
  };

  const loginCardInfo = {
    cardClass: "border mt-4 mt-lg-0",
    segments: [
      {
        type: "card-body",
        class: "px-md-4",
        content: [
          <h5 className="card-title">Sign in to your WaFd Bank Credit Card (eZCard)</h5>,
          <Button {...wafdCardsBtnData} />
        ]
      },
      {
        type: "card-footer",
        class: "bg-light",
        content: [
          <div className="row">
            <div className="col col-lg-9">
              First time user?{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.ezcardinfo.com/?WAFD=/"
                id="wafd-ez-card-enrollment-link"
              >
                Enroll Now
              </a>
            </div>
            <div className="col-auto col-lg-3 text-nowrap">
              <a href="/#" onClick={handleShowHelp} className="text-decoration-none" id="wafd-ez-card-help-link">
                <Icon name="question-circle" lib="far" class="mr-1" />
                Help
              </a>
            </div>
            {showHelp && <ModalGeneric {...loginHelpModalData} />}
          </div>
        ]
      }
    ]
  };

  const heroChevronData = {
    id: "credit-cards-hero",
    ...getHeroImgVariables(imgData),
    altText: "Women making a credit card tap payment at a shop counter.",
    chevronBgClass: "bg-green-60",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Low Rates, Double the Perks With up to 2% Cash Back"
          }
        },
        {
          id: 3,
          button: {
            id: "m-credit-cards-cta",
            class: "btn-white w-sm-75",
            text: "Apply Now",
            onClick: handleShowZipCodeModal,
            type: "button"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Credit Cards"
    }
  ];

  const cashBackDocumentsListData = [
    {
      id: "cash-back-rewards-visa-and-visa-signature-rate",
      text: "WaFd Bank Cash Back Rewards Visa<sup>&reg;</sup> and Visa Signature<sup>&reg;</sup> Rate",
      url: "/documents/credit-cards/wafd-bank-cash-back-rewards-visa-signature-rate.pdf"
    },
    {
      id: "cash-back-rewards-visa-signature-cardholder-agreement",
      text: "WaFd Bank Cash Back Rewards Visa Signature<sup>&reg;</sup> Cardholder Agreement",
      url: "/documents/credit-cards/wafd-bank-cash-back-rewards-visa-signature-cardholder-agreement.pdf"
    },
    {
      id: "cash-back-rewards-visa-cardholder-agreement",
      text: "WaFd Bank Cash Back Rewards Visa<sup>&reg;</sup> Cardholder Agreement",
      url: "/documents/credit-cards/wafd-bank-cash-back-rewards-visa-cardholder-agreement.pdf"
    },
    {
      id: "visa-guide-to-benefits-signature-tier-version",
      text: "Visa<sup>&reg;</sup> Guide to Benefits (Signature tier version)",
      url: "/documents/credit-cards/visa-guide-to-benefits-(signature-tier-version).pdf"
    },
    {
      id: "visa-guide-to-benefits-traditional-tier-version",
      text: "Visa<sup>&reg;</sup> Guide to Benefits (Traditional tier version)",
      url: "/documents/credit-cards/visa-guide-to-benefits-(traditional-tier-version).pdf"
    },
    {
      id: "scorecard-cash-back-rewards-program-rules",
      text: "ScoreCard<sup>&reg;</sup> Cash Back Rewards Program Rules",
      url: "/documents/credit-cards/scorecard-cash-back-rewards-program-rules.pdf"
    },
    {
      id: "secured-credit-card-interest-rate-fee-schedule",
      text: "WaFd Bank Secured Visa<sup>&reg;</sup> Credit Card Interest Rate and Fee Schedule",
      url: "/documents/credit-cards/wafdbank-secured-interest-rate-fee-schedule.pdf"
    },
    {
      id: "secured-credit-card-cardholder-agreement",
      text: "WaFd Bank Secured Visa<sup>&reg;</sup> Credit Card Cardholder Agreement",
      url: "/documents/credit-cards/wafdbank-secured-credit-card-cardholder-agreement.pdf"
    }
  ];

  const faqAccordionData = {
    id: "personal-credit-cards-faq-section",
    faqCategoryNames: ["Credit Cards"],
    showContactBar: false
  };

  const cardsData = useCardBlogsData([
    "/blog/building-credit/how-to-use-credit-cards",
    "/blog/building-credit/how-to-avoid-delinquent-credit-card-payments"
  ]);
  let featuredCardsData = getFeaturedCardBlogData(cardsData, { hasText: false });
  // First card is not blog, thus manually offset the id index
  featuredCardsData = featuredCardsData.map((cardData, index) => ({
    ...cardData,
    id: `credit-cards-card-0${index + 2}`
  }));

  const articleCardsData = {
    cards: [
      {
        id: "credit-cards-card-01",
        url: "/personal-banking/debit-cards/digital-debit-card",
        title: "Add Your Credit Card To Your Digital Wallet",
        image: {
          imgVariable: imgData.CardImage1.childImageSharp.gatsbyImageData,
          altText: "Woman paying for dessert with digital debit card mobile pay."
        }
      },
      ...featuredCardsData
    ]
  };

  const creditCardIconsData = [
    {
      title: "No Annual Fee",
      imageSource: "../../images/icons/icon-no-annual-fee.svg",
      id: "no-annual-fee"
    },
    {
      title: "1% Cash Back",
      text: "earned for all qualified purchases.",
      imageSource: "../../images/icons/icon-1percent-cash-back.svg",
      id: "one-percent-cash-back"
    },
    {
      title: "Up to 2% Cash Back<sup>*</sup>",
      text: "can be earned for gas, grocery, and pharmacy purchases for Visa Signature<sup>&reg;</sup> cardholders.",
      imageSource: "../../images/icons/icon-2percent-cash-back.svg",
      id: "up-to-2-percent-cash-back"
    },
    {
      title: "Roadside Dispatch<sup>&reg;</sup>",
      text: "<sup>**</sup>Get security and convenience wherever your travels take you.",
      imageSource: "../../images/icons/icon-roadside-dispatch.svg",
      id: "roadside-dispatch"
    },
    {
      title: "ID Navigator",
      text: "<sup>**</sup>Get greater peace of mind with identity theft protection.",
      imageSource: "../../images/icons/icon-id-navigator.svg",
      id: "id-navigator"
    },
    {
      title: "Travel & Emergency Assistance Services",
      text: "<sup>**</sup>in eligible scenarios for Visa Signature<sup>&reg;</sup> Cards.",
      imageSource: "../../images/icons/icon-travel-emergency-assistance.svg",
      id: "travel-and-emergency-assistance-services"
    }
  ];

  const secureCreditCardIconsData = [
    {
      title: "Current WaFd Bank Clients Only",
      imageSource: "../../images/icons/icon-wafd-clients.svg",
      id: "secure-cc-wafd-clients"
    },
    {
      title: "No Annual Fee and No Hidden Finance Charges",
      imageSource: "../../images/icons/icon-no-annual-fee.svg",
      id: "secure-cc-no-annual-fee"
    },
    {
      title: "Builds Credit",
      text: "Reports to Four Major Credit Bureaus.",
      imageSource: "../../images/icons/icon-credit.svg",
      id: "secure-cc-build-credit"
    },
    {
      title: "Roadside Dispatch<sup>&reg;</sup>",
      text: "<sup>**</sup>Get security and convenience wherever your travels take you.",
      imageSource: "../../images/icons/icon-roadside-dispatch.svg",
      id: "secure-cc-roadside-dispatch"
    },
    {
      title: "ID Navigator",
      text: "<sup>**</sup>Get greater peace of mind with identity theft protection.",
      imageSource: "../../images/icons/icon-id-navigator.svg",
      id: "secure-cc-id-navigator"
    }
  ];

  const CreditCardIconsSection = (props) => {
    return (
      <div className="row row-cols-1 row-cols-sm-3">
        {props.data.map((block, index) => {
          return (
            <div key={index} id={block.id} className="text-center mb-3 px-2">
              <img src={block.imageSource} alt={`${block.title} Icon`} className="mb-3" style={{ width: "96px" }} />
              <h3 className="font-weight-bold mb-0" dangerouslySetInnerHTML={{ __html: block.title }} />
              {block.text ? <h4 dangerouslySetInnerHTML={{ __html: block.text }} /> : null}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      {/* Credit Cards Login Section */}
      <section className="container border-bottom">
        <div className="row">
          <div className="col-lg-7 col-xl-8">
            <h1>WaFd Credit Cards with Competitive Rates and Great Rewards</h1>
            <h3>
              Whether you want cash back, or a chance to earn rewards, it is easy to{" "}
              <button
                id="wafd-cash-back-rewards-visa-apply-link"
                className="btn-anchor-link text-underline"
                onClick={handleShowZipCodeModal}
              >
                apply online
              </button>
              .
            </h3>
          </div>
          <div className="col-sm-8 col-md-7 col-lg-5 col-xl-4">
            <CardGeneric {...loginCardInfo} />
            <p className="mt-2 mb-0">
              Looking for the{" "}
              <Link id="small-business-credit-card-link" to="/business-banking/small-business-credit-card">
                Community Small Business Credit Card?
              </Link>
            </p>
          </div>
        </div>
      </section>
      {/* Cash Back Credit Card Section */}
      <section className="container pb-0 border-bottom" id="apply-now-section">
        <h2>WaFd Cash Back Credit Card</h2>
        <div className="row py-sm-1">
          <div className="col-md-3 text-left">
            <StaticImage
              src="../../../images/credit-cards/thumbnail-credit-signature-horizontal.jpg"
              alt="WaFd Bank Cash Back Credit Card"
              placeholder="blurred"
              className="mx-auto text-center "
              quality="100"
            />

            <div className="mb-5 align-items-sm-start d-flex flex-column">
              <div className="w-100 my-3">
                <button
                  id="credit-card-apply-now-cta"
                  className="btn btn-primary btn-block no-min-width"
                  onClick={handleShowZipCodeModal}
                >
                  Apply Now
                </button>
              </div>
              <ZipCodeFilter
                showModal={showZipCodeModal}
                handleCloseModal={handleCloseZipCodeModal}
                eligibleUrl={
                  "https://creditcards.wafdbank.com/apply/?store=wafdbank&product=wafd-bank-cash-back-rewards-visa-credit-card"
                }
              />
              <Link
                to="#terms-and-conditions-section"
                id="terms-and-conditions-section-anchor-link"
                className="text-decoration-none"
              >
                <Icon name="arrow-down" class="mr-2 mt-1" />
                Terms and Conditions
              </Link>
              <button
                className="btn btn-anchor-link no-min-width px-0 my-2 text-left"
                onClick={toggleShowCashBackCardRate}
                id="cash-back-cc-rate-btn"
              >
                <Icon lib="fas" name={showCashBackCardRate ? "chevron-up" : "chevron-down"} class="mt-1 mr-2" />
                Cash Back Credit Card Rate
              </button>
              {showCashBackCardRate && (
                <div className="text-green-60 font-weight-bold pl-4">
                  17.74% Annual Percentage Rate (APR) for Purchases
                </div>
              )}
            </div>
          </div>
          <div className="col-md-9">
            <CreditCardIconsSection data={creditCardIconsData} />
          </div>
        </div>
      </section>
      <section className="container pb-0">
        <h2 className="font-weight-bold">Looking to repair or build your credit?</h2>
        <h2>WaFd Bank Secured Credit Card</h2>
        <div className="row py-sm-1">
          <div className="col-md-3 text-left">
            <StaticImage
              src="../../../images/credit-cards/thumbnail-credit-signature-horizontal.jpg"
              alt="WaFd Bank Cash Back Credit Card"
              placeholder="blurred"
              className="mx-auto text-center "
              quality="100"
            />

            <div className="mb-5 align-items-sm-start d-flex flex-column">
              <div className="w-100 my-3">
                <Link id="contact-local-banker" to="/locations" className="btn btn-primary btn-block no-min-width">
                  Contact a Local Banker
                </Link>
              </div>
              <Link
                to="#terms-and-conditions-section"
                id="terms-and-conditions-section-anchor-link-2"
                className="text-decoration-none"
              >
                <Icon name="arrow-down" class="mr-2 mt-1" />
                Terms and Conditions
              </Link>
              <button
                className="btn btn-anchor-link no-min-width px-0 my-2 text-left"
                onClick={toggleShowSecureCreditCardData}
                id="cash-back-secure-cc-rate-btn"
              >
                <Icon lib="fas" name={showSecureCreditCardData ? "chevron-up" : "chevron-down"} class="mt-1 mr-2" />
                Cash Back Credit Card Rate
              </button>
              {showSecureCreditCardData && (
                <div className="text-green-60 font-weight-bold pl-4">
                  25.49% Annual Percentage Rate (APR) for Purchases
                </div>
              )}
            </div>
          </div>
          <div className="col-md-9">
            <CreditCardIconsSection data={secureCreditCardIconsData} />
          </div>
        </div>
      </section>
      <StretchedLinkCards {...articleCardsData} />
      <FaqAccordion {...faqAccordionData} />
      {/* Disclaimers */}
      <section id="terms-and-conditions-section" className="container pt-0 text-sm text-gray-60">
        <h5>Terms and Conditions</h5>
        <ul className="my-3 list-unstyled">
          {cashBackDocumentsListData.map((document) => (
            <>
              <li key={document.id}>
                <a
                  id={document.id}
                  href={document.url}
                  target="_blank"
                  className="text-decoration-none"
                  rel="noreferrer"
                >
                  <Icon class="mr-2" name="file-pdf" />
                  <span dangerouslySetInnerHTML={{ __html: document.text }} />
                </a>
              </li>
            </>
          ))}
        </ul>
        <p id="disclaimer-apr">
          <sup>&dagger;</sup>Annual Percentage Rate (APR) The cost to borrow money expressed as a yearly percentage.
        </p>
        <p id="disclaimer-1">
          * 2% cash back can be earned for gas, grocery, and pharmacy purchases for Visa Signature<sup>&reg;</sup>{" "}
          cardholders and 1% cash back earned for all qualified purchases for both Visa<sup>&reg;</sup> and Visa
          Signature<sup>&reg;</sup> cardholders.
        </p>
        <p id="disclaimer-2">
          ** Certain terms, conditions, and exclusions apply. Subject to credit approval. Please refer to your Visa
          Guide to Benefits and Cardholder Agreement for details.
        </p>
        <p>
          See the{" "}
          <Link id="wafd-privacy-notice" to="/privacy-center">
            WaFd Bank Privacy Notice
          </Link>
          .
        </p>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default CreditCards;
